@import './style/colors.scss';
@import './style/constants.scss';

/* Style for scrollbar */
/* Initially hiding scrollbar */
.scroll-wrap {
  scrollbar-gutter: stable;
  overflow: hidden !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  &:hover {
    overflow: auto !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
  }
}

.scroll-wrap::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 8px;
  background-color: transparent;
}

/* Style for scrollbar thumb */
.scroll-wrap::-webkit-scrollbar-thumb {
  opacity: 0;
  background: rgba(0, 0, 0, 0); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Border radius of the thumb */
}

/* On hover, show scrollbar */
/* Adjust the opacity value as per your preference */
/* This will make the scrollbar appear on hover */
.scroll-wrap:hover::-webkit-scrollbar {
  visibility: visible;
  opacity: 1 !important; /* Show scrollbar on hover */
}

.scroll-wrap:hover::-webkit-scrollbar-thumb {
  opacity: 1 !important; /* Show scrollbar on hover */
  background: rgba(0, 0, 0, 0.25);
}

html {
  #root {
    height: 100%;
    position: relative;
    z-index: 1;
  }
  body {
    background-color: $over-background;
    overflow: hidden;
    overflow-x: auto;
    height: 100vh;
    * {
      font-family: Arial, Helvetica, sans-serif;
    }

    // all the .ui are overwriting semantic styles
    * :not(i),
    .ui,
    .ui.form input[type='text'],
    .ui.dropdown,
    .ui.header {
      font-family: 'SF Pro', Arial, sans-serif;
      font-size: $mpe-base-font-size;
      letter-spacing: -0.36px;
      font-weight: 300;
    }

    div.DraftEditor-root * :not(i) {
      font-weight: inherit;
    }

    div.markdown-text-container {
      strong * {
        font-weight: 700;
      }
      em * {
        font-weight: 400;
      }
    }

    // This class should be changed if SPECIAL TEST in CalendarModal.test.tsx is failing
    // Replace all Box-sc-18eybku-0 classes with new one that is generated from failed snapshots
    // More explanation in CalendarModal.test.tsx
    .Box-sc-18eybku-0 {
      max-width: none;
      .table-filter-actions-menu-list {
        overflow: hidden;
        max-width: 220px;
      }
      .album-task-details-actions {
        max-width: 150px;
        overflow: hidden;
      }
      .apps-canvas-task-details-actions {
        max-width: 150px;
        overflow: hidden;
      }
      .copy-workflow-task-details-actions {
        max-width: 150px;
        overflow: hidden;
      }
      .video-task-details-actions {
        max-width: 150px;
        overflow: hidden;
      }
      .ui-components-calendar-with-time-timepicker {
        .Box-sc-18eybku-0 {
          z-index: 111;
        }
      }
      .due-dates-spinner.Box-sc-18eybku-0 {
        .Box-sc-18eybku-0 .Box-sc-18eybku-0 {
          z-index: 115;
        }
      }
    }

    .Box-sc-18eybku-0:has(.album-task-details-actions) {
      min-width: 150px;
    }

    .Box-sc-18eybku-0:has(.apps-canvas-task-details-actions) {
      min-width: 150px;
    }

    .Box-sc-18eybku-0:has(.copy-workflow-task-details-actions) {
      min-width: 150px;
    }

    .Box-sc-18eybku-0:has(.video-task-details-actions) {
      min-width: 150px;
    }

    .mpe-toast__message {
      z-index: 120;
    }

    b,
    strong {
      font-weight: bold;
    }

    .mpe-app_wrapper {
      background: $white;
      height: 100%;
      min-height: 100%;
      width: 100%;
      min-width: $app-min-width;
      overflow: hidden;
      margin: 0 auto;
      
      .icon.link.disabled:hover {
        opacity: 0.45 !important;
        pointer-events: none;
      }
    }
  }
}